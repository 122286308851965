import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Logo from './components/Logo';
import HeroSection from './components/HeroSection';
import DescripcionGeneral from './components/DescripcionGeneral';
import Caracteristicas from './components/Caracteristicas';
import Beneficios from './components/Beneficios';
import NominaComponent from './components/Nomina';
import RegisterForm from './components/RegisterForm';
import { useResponsiveMenu } from './hooks/useResponsiveMenu';
import Footer from './components/Footer';

const AppWrapper = styled.div`
  min-height: 100vh;
`;

const Header = styled.header`
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    position: relative;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 200px;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #1C212D;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: #F3BA30;
    transition: width 0.3s ease;
  }
  
  &:hover {
    color: #F3BA30;
    
    &::after {
      width: 80%;
    }
  }

  &.active {
    color: #F3BA30;
    
    &::after {
      width: 80%;
    }
  }
`;

const NavButton = styled.button`
  background-color: #F3BA30;
  color: #1C212D;
  padding: 1rem 3rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  
  &:hover {
    background-color: #FFD700;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);
  const { isMenuOpen, setIsMenuOpen, isMobile } = useResponsiveMenu();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);

      // Detectar sección activa
      const sections = ['descripcion', 'caracteristicas', 'beneficios', 'nomina'];
      const current = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      
      if (current) {
        setActiveSection(current);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <AppWrapper>
      <Header isScrolled={isScrolled}>
        <Nav>
          <Logo light={false} />
          {isMobile && (
            <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? '✕' : '☰'}
            </MenuButton>
          )}
          <NavLinks isOpen={isMenuOpen}>
            {['descripcion', 'caracteristicas', 'beneficios', 'nomina'].map((section) => (
              <NavLink 
                key={section}
                href={`#${section}`}
                className={activeSection === section ? 'active' : ''}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection(section);
                  setIsMenuOpen(false);
                }}
              >
                {section === 'descripcion' ? 'Descripción general' :
                 section === 'caracteristicas' ? 'Características' :
                 section === 'beneficios' ? 'Beneficios' :
                 'Nómina de sueldos'}
              </NavLink>
            ))}
            <NavButton onClick={() => {
              setIsRegisterFormOpen(true);
              setIsMenuOpen(false);
            }}>
              Contactanos
            </NavButton>
          </NavLinks>
        </Nav>
      </Header>
      <Routes>
        <Route path="/" element={
          <>
            <HeroSection onRegisterClick={() => setIsRegisterFormOpen(true)} />
            <DescripcionGeneral />
            <Caracteristicas />
            <Beneficios />
            <NominaComponent />
          </>
        } />
      </Routes>
      <RegisterForm 
        isOpen={isRegisterFormOpen} 
        onClose={() => setIsRegisterFormOpen(false)} 
      />
      <Footer />
    </AppWrapper>
  );
}

export default App;